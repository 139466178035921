const Images = {
  PREVIEW_CEKAONICA: require("./images/preview-cekaonica.png"),
  PREVIEW_ZAKAZIVAC: require("./images/preview-zakazivac.png"),
  PREVIEW_PREGLED: require("./images/preview-pregled.png"),
  MINISTARSTVO_ZDRAVLJA_LOGO: require("./images/ministarstvo_zdravlja_logo.png"),
  RFZO_LOGO: require("./images/rfzo_logo.png"),
  BATUT_LOGO: require("./images/batut_logo.png"),
  IZJZV_LOGO: require("./images/izjzv_logo.png"),
  BENEFITS_1: require("./images/benefits_1.jpg"),
  BENEFITS_2: require("./images/benefits_2.jpg"),
  NAPREDNI_EKARTON: require("./images/file-cloud.svg").default,
  IZVESTAJI: require("./images/file-search.svg").default,
  BEZBEDNOST: require("./images/shield-key.svg").default,
  IT_PODRSKA: require("./images/customer-agent.svg").default,
  BEZ_IN_TROSKOVA: require("./images/euro-off.svg").default,
  MODERAN_DIZAJN: require("./images/ruler-pencil.svg").default,
  GRACE_KELLY: require("./images/grace-kelly.jpeg"),
  MAP: require("./images/mapa.PNG"),
  STETOSKOP: require("./images/stetoskop.png"),
};

export default Images;
