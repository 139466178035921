import { DropdownChangeParams } from "primereact/dropdown";
import React, { ChangeEvent, useReducer, useRef } from "react";
import { SchedulePresentationController, SchedulePresentationControllerType } from "../../controllers/schedule-presentation/SchedulePresentationController";
import SchedulePresentationDto from "../../model/schedule-presentation/SchedulePresentationDto";
import actionType from "./actionType";
import initialState from "./initialState";
import PresentationFormView from "./PresentationFormView";
import reducerFunction from "./reducerFunction";
import { MessagesSeverityType } from "primereact/messages";
import { handleAxiosCallError, validateEmail, validateStringEmpty } from "../../infrastructure/system/Utils";
import MessageType from "../../infrastructure/system/MessageType";
import Labels from "../../infrastructure/Labels";
import { Toast } from "primereact/toast";

export default function PresentationFormContainer() {
  const [state, dispatch] = useReducer(reducerFunction, initialState);
  const { axiosSchedulePresentation }: SchedulePresentationControllerType = SchedulePresentationController();
  const messages = useRef<Toast>(null);

  const setTextField = (fieldName: string) => (e: ChangeEvent<HTMLInputElement> | DropdownChangeParams) => {
    dispatch({ type: actionType.SET_FIELD, fieldName, value: e.target.value });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const clear = async (messages: any) => {
    try {
      messages?.current?.clear();
    } catch {}
  };

  const showMessage = async (severity: MessagesSeverityType, summary: string, detail?: string) => {
    await clear(messages);
    try {
      messages?.current?.show({
        severity: severity,
        summary: summary,
        detail: detail,
        closable: true,
        sticky: true,
      });
    } catch {}
  };

  const validateInput = () => {
    if (validateStringEmpty(state.firstname)) {
      showMessage(MessageType.ERROR, Labels.FIRSTNAME_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(state.lastname)) {
      showMessage(MessageType.ERROR, Labels.LASTNAME_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(state.email)) {
      showMessage(MessageType.ERROR, Labels.EMAIL_IS_REQUIRED);
      return false;
    }

    if (!validateEmail(state.email)) {
      showMessage(MessageType.ERROR, Labels.EMAIL_IS_NOT_VALID);
      return false;
    }

    if (validateStringEmpty(state.phone)) {
      showMessage(MessageType.ERROR, Labels.PHONE_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(state.institutionName)) {
      showMessage(MessageType.ERROR, Labels.INSTITUTION_IS_REQUIRED);
      return false;
    }

    if (validateStringEmpty(state.location?.label)) {
      showMessage(MessageType.ERROR, Labels.LOCATION_IS_REQUIRED);
      return false;
    }
    return true;
  };

  const schedulePresentation = () => {
    if (!validateInput()) {
      return;
    }

    let presentationForm: SchedulePresentationDto = {
      firstname: state.firstname,
      lastname: state.lastname,
      email: state.email,
      phone: state.phone,
      speciality: state.speciality,
      institutionName: state.institutionName,
      location: state.location.label,
    };

    axiosSchedulePresentation(presentationForm)
      .then((response: any) => {
        showMessage(MessageType.SUCCESS, Labels.SCHEDULED_PRESENTATION_MAIL_SEND);
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return <PresentationFormView setTextField={setTextField} state={state} scrollToTop={scrollToTop} schedulePresentation={schedulePresentation} messages={messages} />;
}
