import { Button } from "primereact/button";
import React, { Ref } from "react";
import Labels from "../../infrastructure/Labels";
import ImagePreviewCarousel from "./image-preview-carousel/ImagePreviewCarousel";

function Header(_: any, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className="layout-header">
      <div className="py-4">
        <div className="header-title font-bold text-center">
          <p className="mx-3 line-height-2">
            {Labels.HEADER_TITLE}
            <br />
            {Labels.APP_NAME.toUpperCase()}
          </p>
        </div>

        <div className="header-content text-center">
          <div className="row">
            <div className="col-8 mx-auto">
              <p className="line-height-2">
                {Labels.HEADER_CONTENT_1}
                <br />
                <br />
                {Labels.HEADER_CONTENT_2}
                <br />
                <br />
                {Labels.HEADER_CONTENT_3}
              </p>
            </div>
          </div>
        </div>

        <div className="header-content text-center" id="headerSchedulePresentationButton">
          <Button
            label={Labels.ZAKAZI_PREZENTACIJU}
            className="schedule-presentation p-button-outlined secondary_button my-4"
            onClick={() => {
              const schedulePresentationForm = document.querySelector("#schedulePresentationForm");
              schedulePresentationForm?.scrollIntoView();
            }}
          />
        </div>
      </div>
      <ImagePreviewCarousel />
    </div>
  );
}

export default React.forwardRef(Header);
