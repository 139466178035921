import React from "react";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "./sass/layout.scss";
import NavBarContainer from "./components/navbar/NavbarContainer";
import Header from "./components/header/Header";
import PartnerCarouselView from "./components/partner-carousel/PartnerCarouselView";
import BenefitsView from "./components/benefits/BenefitsView";
import Footer from "./components/footer/Footer";
import CharacteristicsView from "./components/characteristics/CharacteristicsView";
import PresentationFormContainer from "./components/presentation-form/PresentationFormContainer";
import MapView from "./components/map/MapView";

function App() {
  const benefitsRef = React.createRef<HTMLDivElement>();
  const characteristicsRef = React.createRef<HTMLDivElement>();
  const partnersRef = React.createRef<HTMLDivElement>();
  const headerRef = React.createRef<HTMLDivElement>();

  return (
    <div>
      <NavBarContainer refs={[benefitsRef, characteristicsRef, partnersRef, headerRef]} />
      <Header ref={headerRef} />
      <BenefitsView ref={benefitsRef} />
      <CharacteristicsView ref={characteristicsRef} />
      <PartnerCarouselView ref={partnersRef} />
      <PresentationFormContainer />
      <MapView />
      <Footer />
    </div>
  );
}

export default App;
