import Images from "../Images";
import Labels from "../Labels";

const Characteristics = [
  {
    title: Labels.NAPREDNI_EKARTON,
    src: Images.NAPREDNI_EKARTON,
    content: "Svi podaci o pacijentu su lako dostupni i pregledni. Omogućeno je  praćenja istorije bolesti bez korišćenja obimne papirne dokumentacije.",
  },
  {
    title: Labels.IZVESTAJI,
    src: Images.IZVESTAJI,
    content: "Kompletna istorija svih pregleda i pruženih usluga zauvek je sačuvana u okviru Vašeg Heliant Clouda. Dostupni su Vam različiti izveštaji za praćenje Vašeg poslovanja.",
  },
  {
    title: Labels.PODRSKA,
    src: Images.IT_PODRSKA,
    content: "Naš tim Vam stoji na raspolaganju i kao podrška i kao pomoć u svakom trenutku procesa.",
  },
  {
    title: Labels.BEZBEDNOST,
    src: Images.BEZBEDNOST,
    content: "Svi podaci Vaše ustanove se čuvaju na centralnom serveru Heliant Clouda. Za zaštitu podataka i bekap kopije zadužen je naš tim.",
  },
  {
    title: Labels.BEZ_IN_TROSKOVA,
    src: Images.BEZ_IN_TROSKOVA,
    content: "Heliant Cloud ne zahteva interne IT resurse ni IT podršku. Jednostavno se skalira prema potrebama korisnika i prilagođen je radu na bilo kom računaru putem internet pretraživača.",
  },
  {
    title: Labels.MODERAN_DIZAJN,
    src: Images.MODERAN_DIZAJN,
    content: "Heliant Cloud karakteriše moderan dizajn, prilagođen intuiciji medicinskih radnika, čemu su pored naših dizajnera doprineli i naši medicinski saradnici.",
  },
];

export default Characteristics;
