import React, { Ref } from "react";
import Labels from "../../infrastructure/Labels";
import { Carousel } from "primereact/carousel";
import Partners from "../../infrastructure/data/Partners";

interface Partner {
  name: string;
  src: string;
}

function PartnerCarouselView(_: any, ref: Ref<HTMLDivElement>) {
  const itemTemplate = (partner: Partner) => (
    <div className="partner flex flex-column align-items-center m-3 p-3">
      <img src={partner.src} alt={partner.name} className="mb-3" />
      <p className="name mb-0">{partner.name}</p>
    </div>
  );

  const responsiveOptions = [
    {
      breakpoint: "1660px",
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: "1460px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "1260px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "1060px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "860px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <div ref={ref} className="layout-partner-carousel my-8">
      <p className="lg-col-12 header-title font-bold text-center mb-4 px-6">{Labels.HELIANT_PARTNERI}</p>
      <div className="partnersCarousel">
        <Carousel numVisible={5} responsiveOptions={responsiveOptions} value={Partners} itemTemplate={itemTemplate} indicatorsContentClassName="hidden" />
      </div>
      <div className="partnersList">
        {Partners.map((partner: Partner) => (
          <div key={partner.name} className="partner flex flex-column align-items-center m-3 p-3">
            <img src={partner.src} alt={partner.name} className="mb-3" />
            <p className="name mb-0">{partner.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.forwardRef(PartnerCarouselView);
