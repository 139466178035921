const Labels = {
  APP_NAME: "HELIANT Cloud",
  BUTTON_PRIJAVA: "Prijava",
  HEADER_TITLE: "Vi brinite samo o pacijentima, za sve ostalo se oslonite na",
  HEADER_CONTENT_1: "Najnapredniji zdravstveni informacioni sistem za manje i srednje privatne zdravstvene ustanove",
  HEADER_CONTENT_2: "Heliant Cloud pretvara zdravstvene ustanove u produktivne centre za brigu o zdravlju",
  HEADER_CONTENT_3: "Jednostavan i praktičan informacioni sistem koji omogućava lekarima da svu svoju pažnju i vreme usmere na pacijente i preglede.",
  HELIANT_PARTNERI: "Heliant Cloud je proizvod Helianta koji već sarađuje sa institucijama",
  MINISTARSTVO_ZDRAVLJA: "Ministarstvo zdravlja Republike Srbije",
  RFZO: "Republički fond za zdravstveno osiguranje",
  BATUT: 'Institut za javno zdravlje Srbije "Milan Jovanović - Batut"',
  IZJZV: "Institut za javno zdravlje Vojvodine",
  BENEFITI: "Benefiti",
  HELIANT_WEBSITE: "www.heliant.rs",
  PRAVA_ZADRZANA: "2022 Sva prava zadržana Heliant Cloud",
  CHARACTERISTICS: "Karakteristike",
  NAPREDNI_EKARTON: "Napredni eKarton",
  IZVESTAJI: "Izveštaji o pregledima i uslugama",
  BEZBEDNOST: "Bezbednost podataka",
  BEZ_IN_TROSKOVA: "Bez inicijalnih troškova",
  MODERAN_DIZAJN: "Moderan dizajn i intuitivnost",
  STA_PRUZAMO: "Šta pružamo Vašoj ustanovi",
  IME: "IME",
  IME_PLACEHOLDER: "Vaše ime",
  PREZIME: "PREZIME",
  PREZIME_PLACEHOLDER: "Vaše prezime",
  NAZIV_INSTITUCIJE: "Naziv institucije",
  IMEJL_ADRESA: "IMEJL-ADRESA",
  IMEJL_PLACEHOLDER: "Vaša imejl-adresa",
  KONTAKT_TELEFON: "KONTAKT TELEFON",
  KONTAKT_PLACEHOLDER: "Vaš kontakt telefon",
  SPECIJALIZOVANOST: "SPECIJALIZOVANOST",
  SPECIJALIZOVANOST_PLACEHOLDER: "Vaša specijalizovanost",
  LOKACIJA_PREZENTOVANJA: "LOKACIJA PREZENTOVANJA",
  LOKACIJA_PLACEHOLDER: "Izaberite lokaciju prezentovanja",
  ZAKAZITE_PREZENTACIJU: "Zakažite prezentaciju",
  ZAKAZI_PREZENTACIJU: "ZAKAŽI PREZENTACIJU",
  NAZAD: "Nazad",
  ZAKAZI: "ZAKAŽI",
  BENEFITI_CLOUD: "Benefiti Heliant Cloud-a",
  FUNKCIONALNOSTI: "Funkcionalnosti",
  INSTITUCIJE: "Institucije",
  POCETNA: "Početna",
  PODRSKA: "Podrška",
  FIRSTNAME_IS_REQUIRED: "Ime ne može biti prazno polje!",
  LASTNAME_IS_REQUIRED: "Prezime ne može biti prazno polje!",
  EMAIL_IS_REQUIRED: "Email ne može biti prazno polje!",
  EMAIL_IS_NOT_VALID: "Email nije u validnom formatu!",
  PHONE_IS_REQUIRED: "Telefon ne može biti prazno polje!",
  INSTITUTION_IS_REQUIRED: "Institucija ne može biti prazno polje!",
  LOCATION_IS_REQUIRED: "Lokacija ne može biti prazno polje!",
  SCHEDULED_PRESENTATION_MAIL_SEND: "Mejl sa Vašim podacima za zakazivanje prezentacije uspešno poslat",
  LABEL_REQUIRED: " *",
};

export default Labels;
