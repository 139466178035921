import React, { Ref } from "react";
import Labels from "../../infrastructure/Labels";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Facebook, Linkedin, Instagram } from "react-feather";
import SocialMedia from "../../infrastructure/data/SocialMedia";
import { mdiAlphaCCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

interface NavBarProps {
  scrollAndClose: (index: number) => (e: any) => void;
  scrollIntoPresentationView: () => void;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  redirectToCloudApp: () => void;
}

function Navbar({ visible, setVisible, scrollAndClose, scrollIntoPresentationView, isMobile, redirectToCloudApp }: NavBarProps, ref: Ref<HTMLDivElement>) {
  return (
    <div className="layout-navbar" ref={ref}>
      <p className="mb-0">{Labels.APP_NAME}</p>

      <div className="flex align-items-center">
        <Button
          id="navbarSchedulePresentationButton"
          label={Labels.ZAKAZI_PREZENTACIJU}
          className="schedule-presentation p-button-outlined secondary_button mr-5 d-none"
          onClick={scrollIntoPresentationView}
        />

        {!isMobile && <Button label={Labels.BUTTON_PRIJAVA.toUpperCase()} className=" p-button-outlined secondary_button mr-5" onClick={redirectToCloudApp} />}
        <i className="pi pi-bars menu-button cursor-pointer" onClick={() => setVisible(!visible)} />
      </div>

      <Sidebar position="right" fullScreen={isMobile ? true : false} visible={visible} onHide={() => setVisible(false)}>
        <div className="flex flex-column pr-3 justify-content-between">
          <div />
          <div className="flex flex-column align-items-end">
            <p className="cursor-pointer" onClick={scrollAndClose(3)}>
              {Labels.POCETNA}
            </p>
            <p className="cursor-pointer" onClick={scrollAndClose(0)}>
              {Labels.BENEFITI_CLOUD}
            </p>
            <p className="cursor-pointer" onClick={scrollAndClose(1)}>
              {Labels.FUNKCIONALNOSTI}
            </p>
            <p className="cursor-pointer" onClick={scrollAndClose(2)}>
              {Labels.INSTITUCIJE}
            </p>
            {isMobile && (
              <p
                className="cursor-pointer"
                onClick={() => {
                  setVisible(false);
                  redirectToCloudApp();
                }}
              >
                {Labels.BUTTON_PRIJAVA}
              </p>
            )}
          </div>
          <div className="flex flex-column align-items-end">
            <div className="flex align-items-center mb-3">
              <a target="_blank noreferrer" className="mr-3" href={SocialMedia.FACEBOOK}>
                <Facebook className="color-white" />
              </a>
              <a target="_blank noreferrer" className="mr-3" href={SocialMedia.LINKEDIN}>
                <Linkedin />
              </a>
              <a target="_blank noreferrer" href={SocialMedia.INSTAGRAM}>
                <Instagram />
              </a>
            </div>
            <div className="flex rights-reserved justify-content-center align-items-center pb-2">
              <Icon path={mdiAlphaCCircleOutline} size={"13px"} className="mr-2" />
              <p>{Labels.PRAVA_ZADRZANA}</p>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default React.forwardRef(Navbar);
