import React, { ChangeEvent } from "react";
import Labels from "../../infrastructure/Labels";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import Locations from "../../infrastructure/data/Locations";
import fieldName from "./fieldName";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

interface PresentationFormProps {
  setTextField: (fieldName: string) => (e: ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
  state: any;
  scrollToTop: () => void;
  schedulePresentation: () => void;
  messages: React.RefObject<Toast>;
}

export default function PresentationFormView({ setTextField, state, scrollToTop, schedulePresentation, messages }: PresentationFormProps) {
  return (
    <div id="schedulePresentationForm" className="layout-presentation-form mb-4">
      <Toast ref={messages} position="bottom-left" />
      <p className="header-title font-bold text-center mb-6">{Labels.ZAKAZITE_PREZENTACIJU}</p>

      <div className="grid schedule_presentation_cards">
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.IME + Labels.LABEL_REQUIRED}</p>
          <InputText className="w-full" value={state.firstname ?? ""} placeholder={Labels.IME_PLACEHOLDER} onChange={setTextField(fieldName.FIRSTNAME)} />
        </div>
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.IMEJL_ADRESA + Labels.LABEL_REQUIRED}</p>
          <InputText className="w-full" value={state.email ?? ""} placeholder={Labels.IMEJL_PLACEHOLDER} onChange={setTextField(fieldName.EMAIL)} />
        </div>
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.PREZIME + Labels.LABEL_REQUIRED}</p>
          <InputText className="w-full" value={state.lastname ?? ""} placeholder={Labels.PREZIME_PLACEHOLDER} onChange={setTextField(fieldName.LASTNAME)} />
        </div>
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.KONTAKT_TELEFON + Labels.LABEL_REQUIRED}</p>
          <InputText className="w-full" value={state.phone ?? ""} placeholder={Labels.KONTAKT_PLACEHOLDER} onChange={setTextField(fieldName.PHONE)} />
        </div>
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.NAZIV_INSTITUCIJE.toUpperCase() + Labels.LABEL_REQUIRED}</p>
          <InputText className="w-full" value={state.institutionName ?? ""} placeholder={Labels.NAZIV_INSTITUCIJE} onChange={setTextField(fieldName.INSTITUTION_NAME)} />
        </div>
        <div className="col-12 col-lg-6 px-6 my-3">
          <p className="label">{Labels.SPECIJALIZOVANOST}</p>
          <InputText className="w-full" value={state.speciality ?? ""} placeholder={Labels.SPECIJALIZOVANOST_PLACEHOLDER} onChange={setTextField(fieldName.SPECIALITY)} />
        </div>
        <div className="col-12 px-6 my-3">
          <p className="label">{Labels.LOKACIJA_PREZENTOVANJA + Labels.LABEL_REQUIRED}</p>
          <Dropdown className="w-full" options={Locations} optionLabel="label" showClear value={state.location} placeholder={Labels.LOKACIJA_PLACEHOLDER} onChange={setTextField(fieldName.LOCATION)} />
        </div>
      </div>

      <div className="flex justify-content-center">
        <div className="schedule_presentation_button">
          <Button className="primary_button schedule_presentation_button_width" label={Labels.ZAKAZI} onClick={schedulePresentation} />
        </div>
        <div className="flex flex-column align-items-center cursor-pointer backToTopButton" onClick={scrollToTop}>
          <i className="back-icon pi pi-chevron-up pb-2" />
          <p className="back-title">{Labels.NAZAD}</p>
        </div>
      </div>
    </div>
  );
}
