import React, { RefObject, useEffect, useState } from "react";
import Navbar from "./Navbar";

interface NavBarContainerProps {
  refs: RefObject<HTMLDivElement>[];
}

export default function NavBarContainer({ refs }: NavBarContainerProps) {
  const ref = React.createRef<HTMLDivElement>();
  const [visible, setVisible] = useState(false);

  const scrollAndClose = (index: number) => (e: any) => {
    refs[index]?.current?.scrollIntoView();
    setVisible(false);
  };

  const scrollIntoPresentationView = () => {
    const schedulePresentationForm = document.querySelector("#schedulePresentationForm");
    schedulePresentationForm?.scrollIntoView();
  };

  const isMobile: boolean = window.innerWidth <= 768;

  const redirectToCloudApp = () => {
    window.location.href = process.env.REACT_APP_SPA_BASE_URL || window.location.origin;
  };

  useEffect(() => {
    const handleOnScroll = () => {
      if (ref.current && window.pageYOffset >= ref.current?.offsetTop) ref.current.classList.add("sticky");

      const navbarSchedulePresentationButton = document.querySelector("#navbarSchedulePresentationButton");
      const headerSchedulePresentationButton = document.querySelector("#headerSchedulePresentationButton");
      if (headerSchedulePresentationButton && navbarSchedulePresentationButton) {
        if (headerSchedulePresentationButton.getBoundingClientRect().top + headerSchedulePresentationButton.getBoundingClientRect().height < 0 && !isMobile) {
          navbarSchedulePresentationButton.classList.remove("d-none");
        } else {
          navbarSchedulePresentationButton.classList.add("d-none");
        }
      }
    };

    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  });

  return (
    <Navbar
      ref={ref}
      visible={visible}
      setVisible={setVisible}
      scrollAndClose={scrollAndClose}
      scrollIntoPresentationView={scrollIntoPresentationView}
      isMobile={isMobile}
      redirectToCloudApp={redirectToCloudApp}
    />
  );
}
