enum fieldName {
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
  EMAIL = "email",
  PHONE = "phone",
  INSTITUTION_NAME = "institutionName",
  SPECIALITY = "speciality",
  LOCATION = "location",
}

export default fieldName;
