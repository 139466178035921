import MessageType from "./MessageType";

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === "";

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response.data && response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error.response && error.response.data.errors) {
    error.response.data.errors.length = 5;
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const localEmailRegexp = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

const validateEmail = (email: string) => {
  if (localEmailRegexp.test(email)) {
    return true;
  }
  return false;
};

export { validateStringEmpty, handleAxiosCallSuccess, handleAxiosCallError, validateEmail };
