import React from "react";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels";
import Endpoint from "../../infrastructure/system/Endpoint";

export default function MapView() {
  return (
    <div className={`mb-8 ${window.innerWidth > 768 ? "mx-8" : ""}`}>
      <a target="_blank noreferrer" href={Endpoint.HELIANT_LOCATION}>
        <img className={`w-full cursor-pointer ${window.innerWidth > 768 ? "px-6" : ""}`} src={Images.MAP} alt={Labels.APP_NAME} />
      </a>
    </div>
  );
}
