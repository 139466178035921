import React from "react";
import Phones from "../../infrastructure/data/Phones";
import Labels from "../../infrastructure/Labels";
import Icon from "@mdi/react";
import { mdiPhoneHangup, mdiEmailOutline, mdiAlphaCCircleOutline } from "@mdi/js";
import EmailAdresses from "../../infrastructure/data/EmailAdresses";
import SocialMedia from "../../infrastructure/data/SocialMedia";
import { Facebook, Instagram, Linkedin } from "react-feather";

export default function Footer() {
  return (
    <div className="layout-footer">
      <div className="flex justify-content-between pt-6 pb-2 inline-display px-8">
        <div className="col-lg-4 col-md-4 col-12 grid-justify-content-center mb-3 text-center">
          <p className="font-bold mb-1 text-white">{Labels.APP_NAME.toUpperCase()}</p>
          <a target="_blank noreferrer" href={`https:${Labels.HELIANT_WEBSITE}`}>
            {Labels.HELIANT_WEBSITE}
          </a>
        </div>

        <div className="col-lg-4 col-md-4 col-12 d-grid justify-content-center mb-3">
          <div className="inline-flex">
            <div className="mr-3">
              <Icon path={mdiPhoneHangup} size={1} />
            </div>
            <div>
              {Phones.map((phone: string) => (
                <p className="mb-2" key={phone}>
                  {phone}
                </p>
              ))}
            </div>
          </div>

          <div className="inline-flex">
            <div className="mr-3">
              <Icon path={mdiEmailOutline} size={1} />
            </div>
            <div>
              {EmailAdresses.map((email: string) => (
                <a target="_blank noreferrer" className="mb-2" key={email} href={`mailto:${email}`}>
                  {email}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-12 flex footerIcons grid-justify-content-center mb-3">
          <a target="_blank noreferrer" className="mr-4" href={SocialMedia.FACEBOOK}>
            <Facebook />
          </a>
          <a target="_blank noreferrer" className="mr-4" href={SocialMedia.LINKEDIN}>
            <Linkedin />
          </a>
          <a target="_blank noreferrer" href={SocialMedia.INSTAGRAM}>
            <Instagram />
          </a>
        </div>
      </div>
      <div className="flex rights-reserved justify-content-center align-items-center pb-2">
        <Icon path={mdiAlphaCCircleOutline} size={"13px"} className="mr-2" />
        <p>{Labels.PRAVA_ZADRZANA}</p>
      </div>
    </div>
  );
}
