const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  speciality: "",
  institutionName: "",
  location: "",
};

export default initialState;
