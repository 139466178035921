import Images from "../Images";
import Labels from "../Labels";

const Partners = [
  { name: Labels.MINISTARSTVO_ZDRAVLJA, src: Images.MINISTARSTVO_ZDRAVLJA_LOGO },
  { name: Labels.RFZO, src: Images.RFZO_LOGO },
  { name: Labels.BATUT, src: Images.BATUT_LOGO },
  { name: Labels.IZJZV, src: Images.IZJZV_LOGO },
];

export default Partners;
