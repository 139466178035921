import actionType from "./actionType";

const reducerFunction = (state: any, action: any) => {
  switch (action.type) {
    case actionType.SET_FIELD: {
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    }
  }
};

export default reducerFunction;
