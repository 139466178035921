import React, { Ref, useEffect, useState } from "react";
import Benefits from "../../infrastructure/data/Benefits";
import Labels from "../../infrastructure/Labels";
import CardDataType from "../../infrastructure/model/CardDataType";

function BenefitsView(_: any, ref: Ref<HTMLDivElement>) {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const cardStyle = (index: number) => {
    return !isMobile ? `flex justify-content-between flex-row-${index % 2 === 0 ? "reverse" : ""}` : "";
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="mt-8 layout-benefits" ref={ref}>
      <p className="main-title font-bold text-center my-6">{Labels.BENEFITI}</p>

      {Benefits.map(({ title, list, src }: CardDataType, index) => (
        <div className={cardStyle(index)} key={title}>
          <img className={isMobile ? "w-full" : ""} src={src} alt={Labels.BENEFITI} />
          <div className={`w-full my-4 flex justify-content-center align-items-center ${isMobile ? "text-align" : ""}`}>
            <div className={isMobile ? "w-10" : "w-8"}>
              <p className="title font-bold mb-6">{title}</p>
              <div className="line-height-1">
                <ul>
                  {list?.map((c: string) => (
                    <p className="content" key={c}>
                      <li>{c}</li>
                    </p>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.forwardRef(BenefitsView);
