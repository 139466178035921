import React from "react";
import Images from "../../../infrastructure/Images";
import Labels from "../../../infrastructure/Labels";
const Carousel = require("3d-react-carousal").Carousel;

export default function ImagePreviewCarousel() {
  const images = [Images.PREVIEW_ZAKAZIVAC, Images.PREVIEW_CEKAONICA, Images.PREVIEW_PREGLED];

  return (
    <>
      <Carousel
        slides={images.map((image) => (
          <img src={image} alt={Labels.APP_NAME} />
        ))}
      />
    </>
  );
}
