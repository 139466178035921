import React, { Ref } from "react";
import Characteristics from "../../infrastructure/data/Characteristics";
import Labels from "../../infrastructure/Labels";
import CardDataType from "../../infrastructure/model/CardDataType";

function CharacteristicsView(_: any, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className="layout-characteristics mt-8">
      <p className="main-title text-center font-bold my-6">{Labels.CHARACTERISTICS}</p>
      <div className="grid characteristics-margins">
        {Characteristics.map(({ title, content, src }: CardDataType) => (
          <div className="col-12 col-lg-4 my-3 text-center" key={title}>
            <img src={src} alt={title} />
            <div>
              <p className="title font-bold">{title}</p>
              <p className="content">{content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.forwardRef(CharacteristicsView);
