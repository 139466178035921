import Images from "../Images";

const Benefits = [
  {
    title: "Povećana efikasnost i kvalitet lečenja kroz:",
    list: [
      "zakazivač koji omogućava praktičnu i pouzdanu evidenciju zakazanih pregleda i raspoloživosti ordinirajućih lekara",
      "jednostavan i brz pristup eKartonu pacijenta",
      "dostupnost istoriji i dokumentaciji svih pregleda i usluga",
      "brz protok informacija",
      "intuitivni navigacioni sistem",
    ],
    src: Images.BENEFITS_1,
  },
  {
    title: "Smanjeni troškovi i ušteda vremena uz:",
    list: ["brzo zakazivanje pregleda pacijenata", "efikasno upravljanje zakazanim pregledima", "smanjenu dokumentaciju"],
    src: Images.BENEFITS_2,
  },
];

export default Benefits;
