import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/system/Endpoint";
import SchedulePresentationDto from "../../model/schedule-presentation/SchedulePresentationDto";

interface SchedulePresentationControllerType {
  axiosSchedulePresentation: (schedulePresentationDto: SchedulePresentationDto) => Promise<AxiosResponse<any, any>>;
}

export function SchedulePresentationController(): SchedulePresentationControllerType {
  const axiosSchedulePresentation = (schedulePresentationDto: SchedulePresentationDto) => {
    return axios.post(Endpoint.SCHEDULE_PRESENTATION, schedulePresentationDto);
  };

  return { axiosSchedulePresentation };
}

export type { SchedulePresentationControllerType };
